import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import ButtonBase from "@material-ui/core/ButtonBase"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"

import SEO from "../components/SEO"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"

import "../styles/departmentsPage.scss"

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    marginBottom: 5,
  },

  icon: {
    marginRight: 15,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center left",
    color: "transparent",
    height: 60,
    width: 60,
  },

  headingText: {
    color: "#fff",
    fontWeight: 600,
    textAlign: "left",
    fontSize: '1.2rem'
  },
}))

const DepartmentsPage = ({
  data: { departmentsPage },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const classes = useStyles()

  const schema = departmentsPage.data.schema_json.text || ""
  const ogp = departmentsPage.data.ogp_tags || ""
  const meta = {
    title: departmentsPage.data.meta_title,
    description: departmentsPage.data.meta_description,
    keywords: departmentsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {departmentsPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              {departmentsPage.data.departments.map(({ department }) => (
                <Paper
                  className={classes.buttonWrapper}
                  key={shortid.generate()}
                  elevation={1}
                  square
                >
                  <ButtonBase
                    focusRipple
                    className="departmentsButton"
                    component={Link}
                    style={{
                      backgroundImage: `url("${department.document.data.display_image.gatsbyImageData?.images.fallback.src}")`,
                    }}
                    to={`${localizedSlugPrefix(departmentsPage)}/${department.document.data.link
                      }`}
                  >
                    <div
                      className={classes.icon}
                      style={{
                        backgroundImage: `url(${department.document.data.icon.gatsbyImageData?.images.fallback.src})`,
                      }}
                    >
                      Icon
                    </div>
                    <Typography className={classes.headingText}>
                      {department.document.data.title.text}
                    </Typography>
                  </ButtonBase>
                </Paper>
              ))}
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default DepartmentsPage

export const query = graphql`
  query DepartmentsPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    departmentsPage: prismicDepartments(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      lang
      first_publication_date
      last_publication_date
      data {
        title
        departments {          
          department {
            document {
              ... on PrismicDepartment {                
                data {
                  link
                  display_image {
                    gatsbyImageData(layout: FIXED, width: 460, height: 65)
                  }
                  icon {
                    gatsbyImageData(layout: FIXED, width: 60, height: 65)
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

DepartmentsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    departmentsPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
